import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Container from "react-bootstrap/Container";
import Header from "./component/Header";
import TaobaoSearchBox from "./component/TaobaoSearchBox";
import JDSearchBox from "./component/JDSearchBox";
import Footer from "./component/Footer";
import QrCodes from "./component/QrCodes";
import Links from "./component/Links";
import "./App.css";

function App() {
  const isSystemInDarkMode = useMediaQuery({
    query: "(prefers-color-scheme: dark)",
  });

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-bs-theme",
      isSystemInDarkMode ? "dark" : "light",
    );
  }, [isSystemInDarkMode]);

  return (
    <div className="vh-100 d-flex flex-column">
      <Header />
      <Container className="flex-grow-1 content">
        <h1 class="visually-hidden">网购助手</h1>
        <TaobaoSearchBox />
        <JDSearchBox />
        <QrCodes className="mt-5" />
        <Links />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
