import React from "react";
import classNames from "classnames";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChrome,
  faEdge,
  faFirefoxBrowser,
} from "@fortawesome/free-brands-svg-icons";

// import "./Header.css";

function Header(props) {
  // 链接
  const LINKS = [
    {
      url: "https://chrome.google.com/webstore/detail/taobao-helper/dmbbmaaonmjlmomcbcpeohnkbcgcefam",
      name: "Chrome插件",
      icon: faChrome,
    },
    {
      url: "https://microsoftedge.microsoft.com/addons/detail/%E6%B7%98%E5%AE%9D%E5%8A%A9%E6%89%8B/acjfknjielnapamlkdhmahpipkehpepn",
      name: "Edge插件",
      icon: faEdge,
    },
    {
      url: "https://addons.mozilla.org/en-US/firefox/addon/taobao-helper/",
      name: "Firefox插件",
      icon: faFirefoxBrowser,
    },
  ];
  let classes = classNames("header", props.className);
  return (
    <Navbar className={classes} bg="secondary-subtle" expand="lg">
      <Container>
        <Navbar.Brand href="/">网购助手</Navbar.Brand>
        <Navbar.Toggle aria-controls="my-navbar-nav" />
        <Navbar.Collapse id="my-navbar-nav">
          <Nav className="ms-auto">
            {LINKS.map((link) => (
              <Nav.Link
                key={link.name}
                href={link.url}
                rel="help external noreferrer"
                target="_blank"
                active={false}
              >
                <FontAwesomeIcon className="me-1" icon={link.icon} />
                {link.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
