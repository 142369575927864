import React from "react";
import classNames from "classnames";
import Nav from "react-bootstrap/Nav";
import "./Footer.css";

function Footer(props) {
  // 链接
  const LINKS = [
    {
      url: "https://ic.yolo.blue/",
      text: "利息计算器",
    },
    {
      url: "https://toolbox.yolo.blue/",
      text: "开发工具箱",
    },
    {
      url: "https://zhuanlan.zhihu.com/p/656630247",
      text: "数值清单",
    },
    {
      url: "https://snooker.yolo.blue/",
      text: "斯诺克记分牌",
    },
    {
      url: "https://memorial.yolo.blue/",
      text: "那些花儿",
    },
    {
      url: "https://quotehub.yolo.blue/",
      text: "引用中心",
    },
  ];
  let classes = classNames("p-3", "bg-secondary-subtle", props.className);
  return (
    <div className={classes}>
      <Nav className="d-flex justify-content-center" as="ul">
        {LINKS.map((link) => (
          <Nav.Item key={link.text} as="li">
            <Nav.Link
              className="text-reset"
              href={link.url}
              target="_blank"
              rel="external noreferrer"
            >
              {link.text}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <div className="text-center copyright">
        © 2019{" "}
        <a
          className="text-reset text-decoration-none"
          href="https://www.yolo.blue/"
          target="_blank"
          rel="author external noreferrer"
        >
          YOLO
        </a>
      </div>
    </div>
  );
}

export default Footer;
