import React from "react";
import classNames from "classnames";
import "./JDSearchBox.css";

function JDSearchBox(props) {
  let classes = classNames("jd", props.className);

  return (
    <iframe
      className={classes}
      src="https://www.yolo.blue/jd-search.html"
      title="京东搜索"
      width="100%"
      height="80"
      scrolling="no"
    ></iframe>
  );
}

export default JDSearchBox;
