import React from "react";
import classNames from "classnames";
import Nav from "react-bootstrap/Nav";

function Links(props) {
  // 链接
  const LINKS = [
    {
      url: "https://www.ctrip.com/?AllianceID=407139&sid=73837884",
      text: "携程",
    },
    {
      url: "https://www.ly.com/?refid=2000166416",
      text: "同程旅行",
    },
    {
      url: "https://s.click.taobao.com/t?e=m%3D2%26s%3DDkkkutvWD5dw4vFB6t2Z2iperVdZeJviv2laukthwYhnX1vWUft3ZSZwmv%2FxaNBzjw0EVztOIwpD5UTJ%2BnEqlE%2B0bDdosFc8pT944LLWMstzxkr3ZiKlVZqvWcOfEvfoAGIx0oe2X2grKUsTDuTWlW3CCSTosozI3ViV%2F%2FO0BW3DX0%2BHH2IEVcsPm9Epdmj4qICd%2F98fq5ZrVHXOK0Axf2dzRCt8aaiW0oUfcZLfeSMBCuWS4z4brw%3D%3D",
      text: "飞猪",
    },
  ];
  let classes = classNames(props.className);
  return (
    <div className={classes}>
      <Nav className="d-flex justify-content-center" as="ul">
        {LINKS.map((link) => (
          <Nav.Item key={link.text} as="li">
            <Nav.Link
              className="link-secondary"
              href={link.url}
              target="_blank"
              rel="external noreferrer"
            >
              {link.text}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
}

export default Links;
