import React from "react";
import classNames from "classnames";
import Nav from "react-bootstrap/Nav";
import "./QrCodes.css";
import { Image, OverlayTrigger, Popover } from "react-bootstrap";

function QrCodes(props) {
  // 链接
  const LINKS = [
    {
      id: "alipay",
      qr: "qr/alipay.png",
      url: "https://mp.weixin.qq.com/s?__biz=Mzg2MjUxODU3MQ==&mid=2247483811&idx=1&sn=84c79f24227930d9aac178430c73b604&chksm=ce07ede7f97064f14b9aa0780e65c3e859b6dd2017babc7b90ff2307e9c75374548de2d32a7a#rd",
      text: "支付宝红包",
    },
    {
      id: "jd-selected",
      qr: "qr/jd_selected.png",
      url: "https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAOcJK1olXDYDZBoCUBVIMzZNXhpXVhgcDVpCVFRMVnBaRQcLDlZRAAMoUAMJaDtMWUVzBnF0ACcPYABWAxJTTjt9HnUGFigtWC9rXz8WQwRACU8dDRsBVUVWUzlcYw4ZBFhHZDUDSxBsXAgMeA5dDV8BVF5aTVV8HQ8EF2sQXAcDV11dDEoUM28JGloUWwQAUVltOEsUMyRmGmsXXAcBUFlVC0wVM28PH18UXgUFUFhcC0onBG8BK4O58tK23YryspOEobuDtWslbQUyU15UHE1lQj0cHSklbQcyV24CTBZKM20JGl8cbTY",
      text: "京东精选",
      tip: "使用京东APP扫描",
    },
    {
      id: "jd-coupon",
      qr: "qr/jd_coupon.png",
      url: "https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAMMJK1olXDYDZBoCUBVIMzZNXhpXVhgcDwYCXhxDXHBTTkRHA1ocDBsJVEVTbT9aXjVUUUJdDAACFAhHVj9LKwlnR25_AFZDaxBlRhJqWCgWGWB0JwhRBHsSAm4JGFgVWQcBZF5cCUoWBW0KHlwlbQYBZBUzCXsVAm4LH1wdXgEAZF5aDE8WAG8NGVwcWAUyU15UOJO7rLu8ko-6596R9orWpnsnM2w4HFscSQBwFQxJDjknM244GGtKGVtfZFxcCU8eM18",
      text: "京东优惠券",
      tip: "使用京东APP扫描",
    },
  ];

  let classes = classNames(
    "qr-codes",
    "d-flex",
    "flex-wrap",
    "justify-content-center",
    props.className,
  );
  return (
    <Nav className={classes} role="navigation" aria-label="红包">
      {LINKS.map((link) => (
        <OverlayTrigger
          key={link.id}
          placement="top"
          overlay={
            <Popover id={`popover-${link.id}`}>
              {link.tip && <Popover.Header as="h3">{link.tip}</Popover.Header>}
              <Popover.Body>
                <Image
                  src={link.qr}
                  alt={`${link.text}二维码`}
                  width={200}
                  referrerPolicy="no-referrer"
                />
              </Popover.Body>
            </Popover>
          }
        >
          <Nav.Link
            className="link-secondary"
            href={link.url}
            target="_blank"
            rel="external noreferrer"
          >
            {link.text}
          </Nav.Link>
        </OverlayTrigger>
      ))}
    </Nav>
  );
}

export default QrCodes;
