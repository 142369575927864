import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import "./TaobaoSearchBox.css";
import { isMobileBrowser } from "../util";

function TaobaoSearchBox(props) {
  // 淘宝联盟pid
  const PID = "mm_106074403_1023950489_114763100313";

  function generateSearchUrl(key) {
    let encodedKey = encodeURIComponent(key);
    return isMobileBrowser()
      ? `https://ai.m.taobao.com/search.html?q=${encodedKey}&pid=${PID}`
      : `https://ai.taobao.com/search/index.htm?key=${encodedKey}&pid=${PID}`;
  }

  const [keywords, setKeywords] = useState("");
  const [keywordsInput, setKeywordsInput] = useState(null);
  useEffect(() => {
    if (keywordsInput) {
      keywordsInput.focus();
    }
  });

  function handleInputChange(event) {
    setKeywords(event.target.value);
  }

  function handleInputKeyPress(event) {
    if (event.key === "Enter") {
      // event.preventDefault();
      handleSearchClick();
    }
  }

  // noinspection JSUnusedLocalSymbols
  function handleSearchClick(event) {
    // eslint-disable-next-line no-undef
    gtag("event", "search", {
      search_term: keywords,
    });
    window.open(generateSearchUrl(keywords), "_blank");
  }

  let classes = classNames(
    "taobao",
    "d-flex",
    "align-items-center",
    props.className,
  );
  return (
    <InputGroup className={classes}>
      <InputGroup.Text className="fs-3 label">淘宝</InputGroup.Text>
      <Form.Control
        id="keywords"
        className="flex-grow-1"
        type="text"
        placeholder="请输入商品名称关键字"
        value={keywords}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        ref={(input) => {
          setKeywordsInput(input);
        }}
      />
      <Button
        className="search-btn flex-grow-0"
        variant="outline-secondary"
        onClick={handleSearchClick}
      >
        搜索
      </Button>
    </InputGroup>
  );
}

export default TaobaoSearchBox;
